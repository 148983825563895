import CustomCarousel from "../shared/carousel";
import { useState } from "react";
import { Blob_Base_Url } from "../../utils/static";

const HomeBanner = (props) => {
  const [log, setLog] = useState(new Date());
  const { type, items } = props;

  const HandleChange = (e) => {
    const isHidden = isElementOutViewport(document.getElementById(type));
    const session = {
      start: log,
      end: new Date(),
    };
    setLog(new Date());
    const item = (items || [])[e];
    if (!isHidden && item?.id)
      // LogEvent("/api/banner", {
      //   title: `Banner '${item.title}' Is Visible`,
      //   id: `${item.id}`,
      //   type: "Visible",
      //   body: JSON.stringify(session),
      // });
      console.log(item);
  };

  return (
    <CustomCarousel
      id={type}
      interval={5000}
      onChange={HandleChange}
      items={
        items?.length > 0
          ? items.map((s) => <RenderBanner {...s} />)
          : [DefaultBanner[type]]
      }
    />
  );
};

const srcSet = (name) =>
  `${Blob_Base_Url + name}?tr=w-300,h-200,cm-pad_resize,bg-F3F3F3 400w, ${
    Blob_Base_Url + name
  }?tr=w-600,h-320,cm-pad_resize,bg-F3F3F3 9200w`;

const RenderBanner = ({ media, type }) => (
  <div className="home-banner">
    <img
      src={
        Blob_Base_Url +
        media.serverName +
        `?tr=w-${
          type === "Banner" ? "871" : "276"
        },h-400,cm-pad_resize,,bg-F3F3F3`
      }
      // srcSet={srcSet(media.serverName)}
    />
  </div>
);

const DefaultBanner = {
  ads: (
    <div
      className="home-banner"
      style={{
        background: `url(${Blob_Base_Url}/images/fixedAdv.jpg) no-repeat`,
        backgroundSize: "cover",
      }}
    >
      <div className=" p-4">
        <span className="ls1 font-md text-dark">Advertisement</span>
        <h3 className="fw-bold mb-4">
          Growth <br />
          You Need
        </h3>
        <p className="font-md">
          Your Product Can Appear Here..{" "}
          <a href="/" className="font-tiny text-dark text-decoration-none">
            read more
          </a>
        </p>
        <a
          href="mailto:advertise@dealsonopenbox.com"
          className="btn  font-md px-3 py-2 bg-light text-blue fw-600"
        >
          START
        </a>
      </div>
    </div>
  ),
  banner: (
    <div
      className="bg-cover-full home-banner"
      style={{
        background: `url(${Blob_Base_Url}/images/banner-default.jpeg)`,
      }}
    >
      <div className="text-end d-flex align-items-center h-100">
        <h1 className="fw-bold fs-2 ms-5">
          Experience a new <br />
          shopping <span className="text-danger">era</span>
        </h1>
      </div>
    </div>
  ),
};

export default HomeBanner;

function isElementOutViewport(el) {
  var rect = el.getBoundingClientRect();
  return (
    rect.bottom < 0 ||
    rect.right < 0 ||
    rect.left > window.innerWidth ||
    rect.top > window.innerHeight
  );
}
