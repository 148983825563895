import { useState } from "react";
import { Carousel, Stack } from "react-bootstrap";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";



const CustomCarousel = ({ items = [], onChange,...props }) => {


    const [index, setIndex] = useState(0);
    const HandleSlide = (e) => {
        setIndex(e);
        if (onChange)
            onChange(e);
    }

    return (
        <div className="position-relative custom-carousel">
            <Carousel className="rounded-8 overflow-hidden"
                indicators={false}
                prevIcon={<MdKeyboardArrowLeft />}
                nextIcon={<MdKeyboardArrowRight />}
                {...props}
                controls={items.length!==0}
                activeIndex={index} onSelect={HandleSlide}>
                {
                    items.map(x =>
                        <Carousel.Item>
                            {x}
                        </Carousel.Item>)
                }

            </Carousel>
            <Stack className="indicators" direction="horizontal" gap='3'>
                {
                    items.map((_, i) =>
                        <span role='button' className={`${i === index ? 'bg-primary opacity-75' : 'bg-white opacity-50'}`} onClick={() => setIndex(i)} />)
                }
            </Stack>
        </div>
    )

}

export default CustomCarousel;